import axios from 'axios'

export default function request(config){

    const baseURL = 'https://o.yushengtong.cn/'

    const instance = axios.create({
        baseURL:baseURL,
    })

    if(config.url === 'wx_login') { //服务器
    // if(config.url === 'staff_login') {  //本地
        //响应拦截
        instance.interceptors.response.use(success=>{
            if((success.data.status === 0) && (success.headers['x-token'])){
                sessionStorage['x-token'] = success.headers['x-token'];
            }
            return success.data
        },err=>{
            return err
        })
    } else {
        if(!sessionStorage.getItem('x-token')) {
            
            location.replace('/wx_login')    //跳转到登录页面（服务器）
            // location.replace('/login')  //跳转到登录页面（本地）
        }

        //请求拦截
        instance.interceptors.request.use(success=>{
            success.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('x-token')
            return success
        },err=>{
            return err
        })

        //响应拦截
        instance.interceptors.response.use(success=>{
            return success.data
        },err=>{
            return err
        })
    }

    return instance(config)
}